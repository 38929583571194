import { useEffect } from 'react';
import { useAuth } from '@vl-core/useAuth';

// redirect to the user's build_path as required
export function maybeRedirectToBuildPath({ build_path }: { build_path: string }) {
  function stripCurrentStoryPathname(pathname: string) {
    const [storyPrefix] = RegExp('^/vl(nhs|axa|m|s|t)-[^/]*-(story|bug)').exec(pathname) || [];

    return storyPrefix ? pathname.substring(storyPrefix.length) : pathname;
  }

  if (!build_path) return '';

  const { pathname, hostname } = window.location;
  const currentBuildPath = pathname.split('/').find((s) => s.length > 0);

  if (hostname !== 'localhost' && currentBuildPath !== build_path) {
    // OK, I'm on the wrong build_path, time to redirect
    const url = new URL(window.location.href);

    url.pathname = `${build_path}${stripCurrentStoryPathname(url.pathname)}`;
    console.log('Redirecting to build_path (maybeRedirectToBuildPath):', url.toString());
    window.location.replace(url);

    return true;
  }

  return '';
}

function useUserInBothAppAndAdmin() {
  const auth = useAuth();

  // in vl-admin this will be true
  if (Array.isArray(auth)) {
    return auth[0];
  }

  return auth;
}

export default function useRedirectToBuildPath() {
  const { build_path } = useUserInBothAppAndAdmin().user || {};

  useEffect(() => {
    function stripCurrentStoryPathname(pathname: string) {
      const [storyPrefix] = RegExp('^/vl(nhs|axa)-[^/]*-(story|bug)').exec(pathname) || [];

      return storyPrefix ? pathname.substring(storyPrefix.length) : pathname;
    }

    if (build_path && typeof build_path === 'string') {
      const { pathname, hostname } = window.location;
      const currentBuildPath = pathname.split('/').find((s) => s.length > 0);

      if (hostname !== 'localhost' && currentBuildPath !== build_path) {
        // OK, I'm on the wrong build_path, time to redirect
        const url = new URL(window.location.href);

        url.pathname = `${build_path}${stripCurrentStoryPathname(url.pathname)}`;
        console.log('Redirecting to build_path:', url.toString());
        window.location.replace(url);
      }
    }
  }, [build_path]);
}
